import { Circle } from '@mui/icons-material';
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
// import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import React/* , { useEffect, useState } */ from 'react';
// import { styled } from '@mui/material/styles';


function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export function sortByTimestamp(array, key) {
    let newarray = array?.slice().sort((a, b) => new Date(b[key]) - new Date(a[key]));
    return newarray;
}


export function parseDateAndTime(dateTime, dateWithYear = false) {
    if(!dateTime) return "";
    const monthNames = ["Jan", "Feb", "March", "April", "May", "June",
        "July", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const modifiedDateTime = new Date(dateTime);
    if (dateWithYear) {
        return modifiedDateTime.getDate() + " " + monthNames[modifiedDateTime.getMonth()] + " " + modifiedDateTime.getFullYear();
    }

    return modifiedDateTime.getDate() + " " + monthNames[modifiedDateTime.getMonth()] + ", " + formatAMPM(modifiedDateTime);
}


const OrderTimeline = ({ orderDetailInfo }) => {
    // console.log(orderDetailInfo)
    const order_history = (orderDetailInfo.order_history) ? sortByTimestamp(orderDetailInfo.order_history, "timestamp") : [];
    const currentStage = order_history.length

    // const QontoConnector = styled(StepConnector)(({ theme }) => ({
    //     [`&.${stepConnectorClasses.alternativeLabel}`]: {
    //       top: 10,
    //       left: 'calc(-50% + 16px)',
    //       right: 'calc(50% + 16px)',
    //     },
    //     [`&.${stepConnectorClasses.active}`]: {
    //       [`& .${stepConnectorClasses.line}`]: {
    //         borderColor: '#1976d2',
    //       },
    //     },
    //     [`&.${stepConnectorClasses.completed}`]: {
    //       [`& .${stepConnectorClasses.line}`]: {
    //         borderColor: '#1976d2',
    //       },
    //     },
    //     [`& .${stepConnectorClasses.line}`]: {
    //       borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    //       borderTopWidth: 3,
    //       borderRadius: 1,
    //     },
    //   }));

    function getStepColor() {
        let textColor = 'primary';

        if (orderDetailInfo.currentStatus.includes('DELIVERED')) {
            textColor = 'success'
        } else if (orderDetailInfo.currentStatus.includes('FAILED')) {
            textColor = 'error'
        }
        return textColor;
    }

    return (
        <Stepper activeStep={currentStage} orientation="vertical">
            {order_history.map((item, index) => (
                <Step key={index} >
                    <StepLabel icon={<Circle fontSize='small' color={getStepColor()} />}>
                        <Typography variant="body2">
                            <span style={{ fontWeight: 'normal' }}>Activity:</span><span style={{ fontWeight: 500 }}> {item.orderStatusDescription}</span>
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                            <Typography variant="caption" component='div' fontWeight={500} >
                                <span style={{ fontWeight: 'normal' }}>Location: </span><span style={{ fontWeight: 500 }}>{item.currentLocation}</span>
                        </Typography>
                        <Typography variant="caption" component='div' fontWeight={500}>
                            <span style={{ fontWeight: 'normal' }}>Remarks: </span><span style={{ fontWeight: 500 }}>{item.remark}</span>
                    </Typography>
                    <Typography component='div' variant="caption">
                        {parseDateAndTime(item.timestamp)}
                    </Typography>
                </Box>
                    </StepLabel>
                </Step >
            ))}
        </Stepper >
    );

}
export default OrderTimeline;