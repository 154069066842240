import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { domainDetails } from "../domainDetails"
import useIsMobile from './isMobile';

export default function TopBar() {
    let host = window.location.host;
    const subdomain = host.split('.')[0];

    const isMobile = useIsMobile();

    return (
        <Box sx={{ width: '100%' }}>
            <AppBar
                position="static"
                elevation={0}
                sx={{
                    bgcolor: '#fff',
                    boxShadow: '0px 2px 3px -3px rgba(0,0,0,0.3)'
                }}
            >
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        <img hidden={['tracking', 't'].includes(subdomain)} src={domainDetails[subdomain] ? domainDetails[subdomain].logo : domainDetails.default.logo} height={isMobile ? 25 : 40} style={{ margin: '10px' }} alt="logo" />
                    </Box>
                    <Typography variant='body2' sx={{ color: '#000', display: 'flex', alignItems: 'center' }} fontWeight={500}>Powered by <img style={{ marginLeft: 8 }} src="/img/prozo.png" height={isMobile ? 20 : 30} alt="logo" /></Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
