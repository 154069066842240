import * as React from 'react';
import { domainDetails } from "../domainDetails";
import { Outlet } from 'react-router-dom';
import SearchBar from './SearchBar';
import { Box, Container, Grid } from '@mui/material';
import TopBar from './TopBar';
import useIsMobile from './isMobile';

export default function Layout() {
    let host = window.location.host;
    const subdomain = host.split('.')[0];
    const currentDomain = domainDetails[subdomain];

    React.useEffect(() => {
        let title = 'Proship Order Tracking';
        if (domainDetails[host]) {
            title = `${domainDetails[host].name} - Order Tracking - Powered By Proship`;
        }
        document.title = title;
    }, [host]);

    const largeBannerImage = currentDomain?.banner?.large || null;
    const smallBannerImage = currentDomain?.banner?.small || null;
    const webUrl = currentDomain?.webUrl || null;
    console.log('largeBannerImage', largeBannerImage);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {(largeBannerImage && smallBannerImage) ? (
                <Banner webUrl={webUrl} largeBannerImage={largeBannerImage} smallBannerImage={smallBannerImage} />
            ) : (
                <TopBar />
            )}
            <Box sx={{ flexGrow: 1 }}>
                <Container sx={{ my: 3, mb: 15 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SearchBar />
                        </Grid>
                        <Grid item xs={12}>
                            <Outlet />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {largeBannerImage && smallBannerImage && (
                <Box sx={{ mt: 'auto' }}>
                    <TopBar />
                </Box>
            )}
        </Box>
    );
}

function Banner({ largeBannerImage, smallBannerImage, webUrl }) {

    const isMobile = useIsMobile();

    return (
        <Box
            sx={{
                width: '100%',
                cursor: 'pointer',
                'img': {
                    width: '100%'
                }
            }}
            onClick={() => {
                if (webUrl) window.open(webUrl);
            }}
        >
            <img src={isMobile ? smallBannerImage : largeBannerImage} alt="Brand Banner" />
        </Box>
    );
}
