import React, { useContext } from "react";

const LoadingContext = React.createContext()

export default function LoadingProvider({ children }) {

    const [isLoading, setIsLoading] = React.useState(false);

    return (
        <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
            {children}
        </LoadingContext.Provider>
    )

}

export function useLoadingState() {
    return useContext(LoadingContext)
}