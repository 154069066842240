import React from "react";

function useIsMobile() {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        setWidth(window.innerWidth);
    }, []);

    const isMobile = width <= 900;

    return isMobile;
}

export default useIsMobile;