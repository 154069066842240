export const domainDetails = {
    fancode: {
        logo: '/img/867f5c067d544a3f79567a893209f1c4 (1).svg',
        id: '630e193b397f3f66d5c7a6d5',
        name: 'Fancode'
    },
    cureayu: {
        logo: '/img/cureayu.png',
        id: '65c1cd8117be30519ff55096',
        name: 'Cureayu'
    },
    neemans: {
        logo: '/img/neemans.svg',
        id: '6634c8ad2fd1cb4630e9d1e8',
        name: 'Neemans',
        webUrl: 'https://neemans.com/',
        banner: {
            large: '/img/neemans_desktop.jpg',
            small: '/img/neemans_mobile.jpg'
        }
    },
    default: {
        logo: '/img/prozo.png'
    },
    comet: {
        logo: '/img/comet.png',
        id: '649c23adca521f1d45138702',
        name: 'Comet'
    },
    sg: {
        logo: '/img/sg.png',
        id: '62eba5701a6b3a0ad61ead99',
        name: 'SG'
    },
    tcpl: {
        logo: '/img/tcpl.png',
        id: '630e1e95397f3f66d5c7a79b',
        name: 'TCPL'
    },
    baidyanathayurved: {
        logo: '/img/baidyanathayurved.png',
        id: '669e35c34731fe7eb440a029',
        name: 'Baidyanath'
    },
    trusoul: {
        logo: '/img/trusoul.png',
        id: '66c47d5880b1e00948490629',
        name: 'Trusoul'
    }
}