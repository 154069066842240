import { CssBaseline, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoadingProvider from "./Context";
import Order from "./components/Order";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Layout from "./components/Layout"

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif'
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '20px !important'
        }
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRadius: '20px !important'
        }
      },
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer />
      <BrowserRouter>
        <LoadingProvider>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/:id" element={<Order />} />
              <Route path="/" element={<Order />} />
            </Route>
          </Routes>
        </LoadingProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
