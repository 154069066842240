import { Divider, Grid, Paper, Typography } from "@mui/material";
import { parseDateAndTime } from "./OrderTimeLine";

export default function OrderDetails({ data }) {

    return (
        <Paper
            sx={{
                boxShadow: '0px 0px 0px 1px rgba(0,0,0,0.1)',
            }}>
            <Typography sx={{ px: 3, py: 2 }} fontWeight={600}>Order Details</Typography>
            <Divider />
            <Grid container spacing={1} sx={{ px: 3, py: 2 }}>
                <Grid item xs={4}><Typography fontWeight={500} variant="body2">Order ID:</Typography></Grid>
                <Grid item xs={8}><Typography variant="body2">{data?.shippingLabelId?.toUpperCase()}</Typography></Grid>
                
                <Grid item xs={4}><Typography fontWeight={500} variant="body2">Order Placed On:</Typography></Grid>
                <Grid item xs={8}><Typography variant="body2">{parseDateAndTime(data?.createdDate, true)}</Typography></Grid>

                {/* <Grid item xs={4}><Typography fontWeight={500} variant="body2">EDD:</Typography></Grid>
                <Grid item xs={8}><Typography variant="body2">{parseDateAndTime(data?.edd, true)}</Typography></Grid>
                 */}
                <Grid item xs={4}><Typography fontWeight={500} variant="body2">Payment Mode:</Typography></Grid>
                <Grid item xs={8}><Typography variant="body2">{data.paymentMode?.toUpperCase()}</Typography></Grid>

                <Grid item xs={4}><Typography fontWeight={500} variant="body2">AWB Number:</Typography></Grid>
                <Grid item xs={8}><Typography variant="body2">{data.waybill?.toUpperCase()}</Typography></Grid>

                <Grid item xs={4}><Typography fontWeight={500} variant="body2">Current Location:</Typography></Grid>
                <Grid item xs={8}><Typography variant="body2">{data?.currentLocation?.toUpperCase()}</Typography></Grid>
                <Grid item xs={4}><Typography fontWeight={500} variant="body2">Courier Partner:</Typography></Grid>
                <Grid item xs={8}><Typography variant="body2">{data?.courierPartnerParent?.toUpperCase()}</Typography></Grid>
                <Grid item xs={4}><Typography fontWeight={500} variant="body2">Invoice Value:</Typography></Grid>
                <Grid item xs={8}><Typography variant="body2">Rs. {data?.invoiceValue}</Typography></Grid>
                <Grid item xs={4}><Typography fontWeight={500} variant="body2">Pickup Location:</Typography></Grid>
                <Grid item xs={8}><Typography variant="body2">{data?.pickupLocation?.toUpperCase()}</Typography></Grid>
                <Grid item xs={4}><Typography fontWeight={500} variant="body2">Delivery Location:</Typography></Grid>
                <Grid item xs={8}><Typography variant="body2">{data?.deliveryLocation?.toUpperCase()}</Typography></Grid>
            </Grid>
        </Paper>
    )
}