import React from "react"
import { LoadingButton } from "@mui/lab"
import { FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, TextField, useMediaQuery, useTheme } from "@mui/material"
import { useSearchParams } from "react-router-dom";
import { useLoadingState } from "../Context";
import useIsMobile from "./isMobile";

export default function SearchBar() {

    const [searchParams] = useSearchParams()

    let searchByValue = searchParams.get('searchBy') || 'waybill';
    let textFieldValue = searchParams.get('ref_awb_number') || '';

    const [searchBy, setSearchBy] = React.useState(searchByValue)
    const [value, setValue] = React.useState(textFieldValue)
    const { isLoading } = useLoadingState()
    const isMobile = useIsMobile()

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));

    const placeholderText = "You can track your order here";
    // const fontSize = isXs ? '6px' : isSm ? '9px' : '16px';

    return (
        <Paper sx={{
            p: 5,
            boxShadow: '0px 0px 0px 1px rgba(0,0,0,0.1)',
        }}>
            <form method="get">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel>Search By</FormLabel>
                            <RadioGroup row name="searchBy" value={searchBy} onChange={(e) => setSearchBy(e.target.value)}>
                                <FormControlLabel value="reference" control={<Radio />} label="Reference Number" />
                                <FormControlLabel value="waybill" control={<Radio />} label="AWB Number" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            sx={{
                                '& .MuiInputBase-root': {
                                    px: 3,
                                    pr: 0
                                }
                            }}
                            fullWidth
                            name="ref_awb_number"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            placeholder={placeholderText}
                            InputProps={{
                                endAdornment: !isMobile ? (
                                    <LoadingButton
                                        loading={isLoading}
                                        type="submit"
                                        disabled={value === ''}
                                        variant="contained"
                                        sx={{
                                            width: 150,
                                            height: 54
                                        }}>
                                        Search
                                    </LoadingButton>
                                ) : null
                            }}
                        />
                    </Grid>
                    {isMobile && (
                        <Grid item xs={12}>
                            <LoadingButton loading={isLoading} type="submit" size="large" disabled={value === ''} variant="contained">
                                Search
                            </LoadingButton>
                        </Grid>
                    )}
                </Grid>
            </form>
        </Paper>
    )

}