import axios from "axios";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useLoadingState } from "../Context";
import OrderTimeline from "./OrderTimeLine";
import { Box, Grid, Paper, Typography } from "@mui/material";
import OrderDetails from "./OrderDetail";
import ReportIssue from "./ReportIssue";
import { domainDetails } from "../domainDetails";

export default function Order() {
    const [searchParams] = useSearchParams()
    const { id } = useParams()
    const { isLoading, setIsLoading } = useLoadingState()
    const [data, setData] = React.useState([])
    const [/* found */, setFound] = React.useState(true)
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    let host = window.location.host
    const subdomain = host?.split('.')[0]
    let dd = domainDetails[subdomain];
    let merchantId = dd?.id;
    let searchBy = searchParams.get('searchBy');
    let value = searchParams.get('ref_awb_number');
    let awbSearch = searchParams.get('awbNo');
    React.useEffect(() => {
        const request = {}
        if (searchBy && value) {
            setIsLoading(true)
            if (searchBy === 'reference') {
                request.reference = value
                if (merchantId) {
                    request.merchantId = merchantId
                }
            } else if (searchBy === 'waybill') {
                request.waybills = value
                if (merchantId) {
                    request.merchantId = merchantId
                }
            }
            axios.post(`https://j9nr1xsv61.execute-api.ap-south-1.amazonaws.com/proship/track_waybill_p`, {
                queryStringParameters: request
            }).then((response) => {
                setData(response.data?.message?.waybillDetails?.[0] || []);
                if (response.data?.message?.waybillDetails?.length > 0) {
                    let k = response.data?.message?.waybillDetails?.[0];
                    if (k.failedReason && k.failedReason === 'wrong awb or awb doesn\'t exist')
                        setFound(false)
                }
            }).catch((err) => {
                console.error(err)
            }).finally(() => {
                setIsLoading(false)
            })
        }
        else if (id) {
            request.waybills = id
            if (merchantId) {
                request.merchantId = merchantId
            }
            axios.post(`https://j9nr1xsv61.execute-api.ap-south-1.amazonaws.com/proship/track_waybill_p`, {
                queryStringParameters: request
            }).then((response) => {
                setData(response.data?.message?.waybillDetails?.[0] || []);
                if (response.data?.message?.waybillDetails?.length > 0) {
                    let k = response.data?.message?.waybillDetails?.[0];
                    if (k.failedReason && k.failedReason === 'wrong awb or awb doesn\'t exist')
                        setFound(false)
                }
            }).catch((err) => {
                console.error(err)
            }).finally(() => {
                setIsLoading(false)
            })
        } else if (awbSearch) {
            request.waybills = awbSearch
            if (merchantId) {
                request.merchantId = merchantId
            }
            axios.post(`https://j9nr1xsv61.execute-api.ap-south-1.amazonaws.com/proship/track_waybill_p`, {
                queryStringParameters: request
            }).then((response) => {
                setData(response.data?.message?.waybillDetails?.[0] || []);
                if (response.data?.message?.waybillDetails?.length > 0) {
                    let k = response.data?.message?.waybillDetails?.[0];
                    if (k.failedReason && k.failedReason === 'wrong awb or awb doesn\'t exist')
                        setFound(false)
                }
            }).catch((err) => {
                console.error(err)
            }).finally(() => {
                setIsLoading(false)
            })
        }


        // eslint-disable-next-line
    }, [searchBy, value, id])
    let textColor = 'primary';
    let edd;
    if (data.createdDate) {
        if (data?.edd) {
            edd = new Date(data?.edd);
        }

        if (data.currentStatus.includes('DELIVERED')) {
            textColor = 'success'
        } else if (data.currentStatus.includes('FAILED')) {
            textColor = 'error'
        }
    }
    return (
        <Grid container spacing={2}>
            {data.createdDate && (
                <>
                    <Grid item xs={12} md={6}>
                        <Paper
                            sx={{
                                boxShadow: '0px 0px 0px 1px rgba(0,0,0,0.1)',
                            }}>
                            <Box sx={{ px: 6, py: 5 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {/* <CheckCircleOutlineIcon color={textColor} /> */}
                                    <Typography color={`${textColor}.main`} textTransform="uppercase" variant="h6" fontWeight='bold' sx={{ ml: 0 }}>
                                        {mapStatusToBucket(data.currentStatus).replace(/_/g, " ")}
                                    </Typography>
                                </Box>
                                {edd && (
                                    <>
                                    <Typography gutterBottom variant="h2" fontWeight='bold' sx={{ mb: 0 }} color={`${textColor}.main`}>
                                        {edd.getDate()}{" "}
                                        <Typography variant="caption" textTransform="uppercase" fontWeight={600}>
                                            {months[edd.getMonth()] + ", "}
                                            {edd.getFullYear() + ", "}
                                            {days[edd.getDay()]}
                                        </Typography>
                                    </Typography>
                                    <Typography variant="caption" textTransform="uppercase" fontWeight={600}>
                                        Estimated Date of Delivery
                                    </Typography>
                                </>
                                


                                )}


                            </Box>
                            <Box sx={{
                                p: 5,
                                pt: 0,
                                maxHeight: 554,
                                overflowY: 'hidden',
                                '&:hover': {
                                    overflowY: 'scroll !important',
                                },
                                '&::-webkit-scrollbar': {
                                    width: '3px'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#ccc'
                                },
                                '&::-webkit-scrollbar-track': {
                                    background: '#f1f1f1'
                                }
                            }}>
                                <OrderTimeline orderDetailInfo={data} />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <OrderDetails data={data} />
                            </Grid>
                            <Grid item xs={12}>
                                <ReportIssue data={data} />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
            {!data.createdDate && !isLoading && (searchBy !== null || id) && (
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            boxShadow: '0px 0px 0px 1px rgba(0,0,0,0.1)',
                            borderRadius: 3
                        }}>
                        <Typography variant="h6" color="error" align="center">
                            We couldn't find the order you are looking for.
                        </Typography>
                    </Paper>
                </Grid>
            )}
        </Grid>
    )
}

function mapStatusToBucket(status) {
    const statusMapping = {
        'ORDER_PLACED': 'Order Placed',
        'PICKUP_PENDING': 'Order Placed',
        'PICKUP_FAILED': 'Order Placed',
        'PICKED_UP': 'In Transit',
        'INTRANSIT': 'In Transit',
        'OUT_FOR_DELIVERY': 'Out for delivery',
        'DELIVERED': 'Delivered',
        'FAILED_DELIVERY': 'Delivery Failed',
        'CANCELLED_ORDER': 'Cancelled',
        'RTO_REQUESTED': 'RTO',
        'RTO': 'RTO',
        'RTO_OUT_FOR_DELIVERY': 'RTO',
        'RTO_DELIVERED': 'RTO',
        'RTO_FAILED': 'RTO',
        'LOST': 'Lost',
        'DAMAGED': 'Lost',
        'SHIPMENT_DELAYED': 'In Transit',
        'CONTACT_CUSTOMER_CARE': 'In Transit',
        'SHIPMENT_HELD': 'In Transit',
        'RTO_INTRANSIT': 'RTO',
        'OUT_FOR_PICKUP': 'Order Placed',
        'RTO_CONTACT_CUSTOMER_CARE': 'RTO',
        'RTO_SHIPMENT_DELAY': 'RTO',
        'AWB_REGISTERED': 'Order Placed',
        'EXCHANGE_PICKUP': 'In Transit',
        'EXCHANGE_INTRANSIT': 'In Transit',
        'EXCHANGE_DELIVERED': 'Delivered',
        'MANIFESTED': 'Order Placed',
        'RETURN ORDER PLACED': 'In Transit'
    };

    return statusMapping[status] || status;
}
