import { LoadingButton } from "@mui/lab";
import { Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { toast } from "react-toastify";

export default function ReportIssue({ data }) {

    const [isSubmitting, setISSubmitting] = React.useState(false)

    const onSubmit = (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        formData.append('email', e.target.email.value);
        formData.append('subject', `Proship tracking customer complaint - ${data.waybill}`);
        formData.append('description', e.target.description.value);
        formData.append('status', '2'); // Example value; adjust as needed
        formData.append('priority', '3'); // Example value; adjust as needed
        formData.append('custom_fields[cf_awb_number387695]', data.waybill);
    
        setISSubmitting(true);
    
        axios.post('https://prozo-supportdesk.freshdesk.com/api/v2/tickets', formData, {
            headers: {
                'Authorization': 'Basic NjRwUEp2c1NBZGhpOGpiMG9ZNDpY',
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            document.querySelectorAll('form')[1].reset();
            toast.success("We received your query! We will get back to you as soon as possible");
        }).catch(err => {
            toast.error('We are unable to process your request. Please try again in some time!');
        }).finally(() => {
            setISSubmitting(false);
        });
    }
    

    return (
        <Paper
            sx={{
                boxShadow: '0px 0px 0px 1px rgba(0,0,0,0.1)'
            }}>
            <Typography sx={{ px: 3, py: 3 }} fontWeight={600}>Report an issue</Typography>
            <Divider />
            <form onSubmit={onSubmit}>
                <Grid container spacing={2} sx={{ px: 3, py: 3 }}>
                    <Grid item xs={12}>
                        <TextField required name="name" placeholder="Name" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField required name="email" placeholder="Email Address" type="email" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField required name="description" placeholder="Description" multiline fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton loading={isSubmitting} type="submit" size="large" variant="contained" color="primary">Submit</LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    )
}